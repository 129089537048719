import React from 'react';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import Layout from '../components/Layout';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
`;

const Center = styled.div`
  margin: auto;
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    padding-left: 2rem;
    padding-right: 2rem;
    h1 {
      font-size: 2rem;
    }
  }
`;

const NotFoundPage = () => (
  <Layout isIndex={false}>
    <Helmet>
      <title>404 Not Found</title>
    </Helmet>
    <Wrapper>
      <Center>
        <h1>PAGE NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Center>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
